
import { initializeApp } from "firebase/app";

  const firebaseConfig = {
    apiKey: "AIzaSyDyBNb6t-Q3r0u3-1Kjo7RmeVFt9UA4Hyk",
    authDomain: "futuracrm-2021.firebaseapp.com",
    projectId: "futuracrm-2021",
    storageBucket: "futuracrm-2021.appspot.com",
    messagingSenderId: "848630052035",
    appId: "1:848630052035:web:c0a1ca5fa7f733764a5e76",
    measurementId: "G-G1P3NCERFC"
  }
  // Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;

