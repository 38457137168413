const clientes = [

    {
        id: 1,
        name: "Heber Stein Mazutti",
        email: "heber@99coders.com.br",
        fone: "(11) 99999-9999"
    },

    {
        id: 2,
        name: "João da Silva",
        email: "joao@99coders.com.br",
        fone: "(11) 1111-1111"
    },

    {
        id: 3,
        name: "Maria Martins",
        email: "maria@99coders.com.br",
        fone: "(11) 2222-2222"
    }

];

export default clientes;