import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import firebase from '../../config/firebase';
import clientes from '../../dados/clientes';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import './listacliente.css';

/*
function ListaClientes(){

    const[clientes,setClientes] = useState([]);
 let listaCli = [];

useEffect(function(){
  firebase.getFirestore().collection('clientes').get().then(async function(resultado){
    await resultado.docs.forEach(function(doc){
        listaCli.push({
                id: doc.id,
                nome: doc.data().nome,
                email: doc.data().email,
                fone: doc.data().fone
            });
    })
    setClientes(listaCli);
 })	
}, []);

    return<table className="table table-hover table-bordered">
            <thead>
            <tr className="table-secondary">
                <th scope="col">Código</th>
                <th scope="col">Nome</th>
                <th scope="col">E-mail</th>
                <th scope="col">Telefone</th>
            </tr>
            </thead>
         <tbody>
         {
                clientes.map((cliente) => {
                    return <tr key={cliente.id}>
                    <th scope="row">{cliente.id}</th>
                    <td>{cliente.nome}</td>
                    <td>{cliente.email}</td>
                    <td>{cliente.fone}</td>
                </tr>
                })
            }
            </tbody>
      </table>
}
*/
function ListaClientes(props){



    return <table className="table table-hover table-bordered">
            <thead>
            <tr className="table-secondary">
                <th scope="col">Código</th>
                <th scope="col">Nome</th>
                <th scope="col">E-mail</th>
                <th scope="col">Telefone</th>
                <th scope="col" className="col-acao"></th>
            </tr>
            </thead>
            <tbody>

            {
                clientes.map((cliente) => {
                    return <tr key={cliente.id}>
                    <th scope="row">{cliente.id}</th>
                    <td>{cliente.name}</td>
                    <td>{cliente.email}</td>
                    <td>{cliente.fone}</td>
                    <td>
                     <Link to={'/app/editarcliente/' + cliente.id}><i className="fas fa-edit icone-acao"></i></Link>
                     <Link to='#' onClick={() => props.clickDelete(cliente.id)}><i className="far fa-trash-alt icone-acao red"></i></Link> 
                    </td>
                </tr>
                })
            }
                       
            
            </tbody>
        </table>
}


export default ListaClientes;