import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app.jsx';
import { AuthProvider } from './app/context/auth.jsx';


// After
//import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); 
root.render( <AuthProvider> <App/> </AuthProvider> );

//import ReactDOM from 'react-dom';
//ReactDOM.render(<App/>,document.getElementById('root'));